.new-broadcast-message-root {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.new-message-header-container {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    padding-bottom: 5px;
}

.new-message-header {
    font-size: 18px;
    font-weight: 500;
}

.new-message-input-container {
    display: flex;
    flex-direction: column;
}

.new-message-actions-container {
    display: flex;
    gap: 10px;
}

.message-type-input-container {
    display: flex;
    gap: 10px;
}

.new-message-to-form-control {
    display: flex;
    width: 30%;
}

.new-message-to-form-control {
    display: flex;
    width: 30%;
}