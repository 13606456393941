.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.footer-link {
    cursor: pointer;
    text-decoration: underline;
}