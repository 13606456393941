.dashboard-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.event-invite-table-heading {
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 3px solid var(--primary-color);
}

.invite-button {
    background: #FFFFFF !important;
    border: 1px solid #000000 !important;
    border-radius: unset !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    height: 30px !important;
    
}

.invite-button:focus {
    background: #FFFFFF !important;
}

.invite-button:hover {
    color: var(--primary-color) !important;
}

.invited-status-label {
    font-size: 14px;
    padding: 5px 10px;
    background-color: #EDEDED;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
} 

.dashboard-filters-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.invite-note-icon {
    cursor: pointer;
}