.message-tile-root {
  display: flex;
  padding: 5px;
}

.message-details-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message-details,
.time-details-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.my-message-tile {
  flex-direction: row-reverse !important;
}

.message-text-container {
  background-color: #dddddd !important;
  border-radius: 16px 16px 16px 4px;
  color: #202226;
  display: flex;
  max-width: 60%;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
}

.my-message-tile .message-text-container {
  background-color: #a47a3e !important;
  border-radius: 16px 16px 4px 16px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
}

.message-time {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: #a0aec0;
  font-weight: 400;
}

.message-sender-name {
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: #1a202c;
  font-weight: 800;
}
