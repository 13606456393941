.sidebar-route-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.sidebar-route {
  font-size: 14px;
  cursor: pointer;
}

.selected-route {
  font-size: 16px;
  font-weight: bold;
}

.logout-section {
  display: flex;
  gap: 5px;
  font-size: 14px;
  margin-top: 50px;
}

/* Sidebar styles end */

.rsvp-event-tabs-container {
  background-color: #ebebeb !important;
  min-height: unset !important;
}

.rsvp-event-tabs-container .MuiTabs-indicator {
  height: 4px !important;
  background-color: #000000 !important;
}

.rsvp-event-tab {
  color: #000000 !important;
  padding: 10px !important;
  min-height: unset !important;
}

.no-data-text {
  font-size: 20px;
  font-weight: 600;
  color: #d2d2d2;
  text-align: center;
  margin-top: 20px;
}

/* RSVP EVENT Details Pending state */

.rsvp-event-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 25px 0;

  width: 100%;
}

.rsvp-event-icon-container {
  /* width: 100%; */
  /* height: 100%; */
}

.rsvp-event-icon {
  width: 100px;
  height: 100px;
}

.rsvp-event-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1;
}

.rsvp-event-name {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 45px;
}

.rsvp-event-date {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 26px;
}

.rsvp-event-time-details-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.rsvp-event-time-details {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 18px;
  color: #989898;
}

.rsvp-event-details-button {
  border: 1.5px solid #000000;
  padding: 5px 20px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
}

.rsvp-event-response-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1;
}

.rsvp-event-response-text {
  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-size: 23px;
}

.rsvp-event-respond-by-date {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 23px;
}

.rsvp-event-looking-forward-text {
  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-size: 19px;
  color: #707070;
  text-transform: uppercase;
  max-width: 50%;
}

.rsvp-button {
  padding: 5px 20px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 15px !important;
  border-radius: 50px !important;
  width: 150px !important;
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.rsvp-decline {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px !important;
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  text-transform: uppercase;
}

.response-received-text {
  font-family: "Abhaya Libre", serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  max-width: 50%;
}

/* RSVP EVENT Details Accepted state */

.rsvp-event-acceptance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1;

  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-size: 27px;
  margin-bottom: 30px;
}

/* RSVP EVENT Details Declined state */

.miss-you-image {
  width: 100%;
  max-width: 450px;
}

.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1;

  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-size: 27px;
}

/* Guest Event List styles */

.guest-event-list-root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.guest-event-heading {
  font-size: 18px;
  font-weight: 600;
}

.guest-event-list-container {
  margin-top: 20px;
}

/* Event Card */
.event-card-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event-card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  font-family: "Abhaya Libre", serif;
  height: 100%;
}

.event-card-name {
  font-size: 33px;
  font-weight: 700;
}

.event-card-details {
  font-size: 20px;
  font-weight: 700;
}

.event-card-details-container,
.event-card-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.event-card-details-container {
  cursor: pointer;
}

.event-card-actions-container {
  background-color: #000000;
  gap: 10px;
}

.event-card-address {
  font-size: 19px;
  font-weight: 400;
  color: #ffffff;
}

.event-card-actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.event-card-action-button {
  padding: 5px 10px;
  background-color: #000000;
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  height: 32px;
  cursor: pointer;
  line-height: 1.2;
}

.event-card-action-button-highlighted {
  padding: 5px 10px;
  background-color: #aaaaaa;
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  height: 32px;
  line-height: 1;
  text-transform: uppercase;
  cursor: default;
  line-height: 1.2;

}

.event-card-action-button-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #000000;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
}

.event-card-action-button-link {
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  text-decoration: underline;
}

.event-card-action-button-change-text {
  font-size: 17px;
  font-weight: 400;
  color: #000000;
}

.event-card-respond-by-text {
  font-size: 12px;
  font-weight: 700;
  font-family: "Abhaya Libre", serif;
  color: #acacac;
  margin: 5px 0;
  display: flex;
  justify-content: flex-end;
}

.event-card-details-text {
  font-size: 16px;
  font-weight: 700;
  font-family: "Abhaya Libre", serif;
  color: #000000;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  margin: 15px 0;
}

/* Event Details Styles */

.event-details-root {
  display: flex;
  flex-direction: column;
}

.event-details-heading-container {
  padding: 20px;
  background: #000000;
  box-shadow: 0px 0px 10px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.event-details-name {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  word-break: break-word;
}

.event-details-date {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-size: 25px;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.event-details-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #000000;
  align-items: flex-start;
  margin: 20px;
}

.event-details-heading {
  font-size: 16px;
  font-weight: 600;
}

.event-details-section {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.event-details-section .label {
  color: #989898 !important;
}

.attire-link {
  text-decoration: underline;
  cursor: pointer;
}
/* Popover styles */

.group-members-root {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px;
  min-width: 170px;
}

.group-members-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  overflow-y: scroll;
}

.group-members-heading {
  font-size: 16px;
  font-weight: 600;
}

.group-invite-actions-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Landing Page */

.landing-root,
.landing-details-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.landing-root {
  margin: 30px;
}

.landing-details-section {
  max-width: 60%;
  gap: 10px;
  margin-bottom: 30px;
}

.landing-welcome-heading {
  font-size: 40px;
  font-weight: 400;
  font-family: "Abhaya Libre", serif;
  text-transform: capitalize;
}

.landing-details-icon {
  height: 60px !important;
  width: 60px !important;
  font-size: 60px;
}

.landing-details-heading {
  font-size: 26px;
  font-style: italic;
  text-transform: uppercase;
}

.landing-details {
  font-size: 18px;
}

.landing-details-link {
  font-size: 16px !important;
  font-style: italic;
}

@media screen and (max-width: 600px) {
  .landing-welcome-heading {
    font-size: 30px;
  }
}

/* Event Note container */

.event-note-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 10px 0;
}

.form-note-input {
  border-radius: unset !important;
  border: 1px solid #000000 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background-color: #ffffff !important;

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  .MuiInputBase-input {
    padding: 8px 10px !important;

    /* height: 30px !important;  */
  }

  .MuiInputBase-input::placeholder {
    font-size: 14px !important;
    font-style: italic !important;
  }
}

@media screen and (min-width: 700px) {
  .event-note-form-container {
    max-width: 40%;
  }
  
}

/* Registry CSS Start */

.qr-scanner-image {
  height: 100%;
  width: 100%;
  max-width: 450px;
  max-height: 650px;
}

.registry-heading {
  font-weight: 500;
}

/* Registry CSS End */