.side-panel-root {
  height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: 95%;
  overflow: auto;
  padding-right: 6px;
}

.form-control-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-control-label {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #9C9C9C;
  margin-bottom: 5px;
}

.form-input-control {
  border-radius: unset !important;
  border: 1px solid #000000 !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  .MuiInputBase-input {
    padding: 8px 10px !important;

    /* height: 30px !important;  */
  }
}

.form-select-control {
  border-radius: unset !important;
  border: 1px solid #000000 !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 400 !important;

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  .MuiSelect-select {
    min-width: 100px !important;
    /* height: 30px !important;  */
  }
}

.default-option {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #9C9C9C;
  font-style: italic;
}

.form-control-error {
  color: #d32f2f !important;
}

.form-actions-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.cancel-button {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: unset !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.cancel-button:focus {
  background: #ffffff !important;
}

.cancel-button:hover {
  color: var(--primary-color) !important;
}

.submit-button {
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: unset !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.submit-button:focus {
  background: var(--primary-color) !important;
}

.date-picker-input {
  /* text-align: right; */
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
  border-radius: unset !important;
  height: 40px !important;
  &::placeholder {
    color: #9c9c9c;
  }
}

.date-picker-header,
.date-picker-header .header--btn,
.date-picker-header .header-date,
.date-picker-header .header--clearBtn {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.time--title {
  text-transform: capitalize !important;
}

.input-picker--input {
  text-align: left !important;
}

.guest-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.guest-list-heading {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.event-name-container {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
}

.event-name {
  font-size: 16px;
  font-weight: 500;
}