.dashboard-table-container {
    width: 100% !important;
  }
  
  .dashboard-table-container th,
  .dashboard-table-container td {
    padding: 5px;
  }
  
  .dashboard-table-container th {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  
  .dashboard-table-container td {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  
  .table-data-row {
      border-bottom: 1px solid #d5d5d5;
  }
  
  .table-data-row:hover {
    background-color: #f2f2f2;
  }
  
  .table-header {
    background-color: #ededed !important;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }