.messages-main-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(100vh - 160px);
}

.messages-root {
  display: flex;
  flex-direction: row;
  border: 1px solid #f7f7f7;
  height: 100%;
  min-height: 300px;
}

.message-member-list-container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f7f7f7;
  width: 30%;
}

.message-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 70%;
}

.chat-header-container {
  height: 50px;
  border-bottom: 1px solid #f7f7f7;
}

.chat-header {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  color: #a47a3e;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .message-member-list-container,
  .message-container {
    width: 100% !important;
  }
}

.message-container-hidden,
.message-member-list-container-hidden {
  display: none;
}
