.homepage-container {
    margin: 20px 0;
    font-family: "Abhaya Libre", serif;
}

.homepage-title {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 20px;
}

.homepage-banner-wrapper {
    height: 500px;
    width: 100%;
    margin-bottom: 30px;
}

.homepage-banner-image {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    max-height: 500px;
}

.homepage-welcome-message {
    font-size: 32px;
    padding: 0 20px;
}

.homepage-welcome-name {
    display: flex;
    flex-direction: column;
    font-size: 80px;
    justify-content: center;
}

.homepage-event-date {
    font-size: 24px;
}

.homepage-event-place {
    font-size: 18px;
}

@media screen and (max-width: 1024px) {
    .homepage-title {
        font-size: 40px;
    }

    .homepage-welcome-message {
        font-size: 20px;
    }

    .homepage-banner-wrapper {
        height: 300px;
    }

    .homepage-banner-image {
        max-height: 300px;
    }

    .homepage-welcome-name {
        font-size: 50px;
    }
}