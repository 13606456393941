.faq-container {
    font-family: "Abhaya Libre", serif;
    max-width: 1600px;
    align-items: center;
    margin: 20px auto;
}

.faq-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}

.faq-q-and-a-container {
    margin: 15px 0;
    padding: 20px;
    text-align: left;
    
}

.faq-question {
    font-weight: 600;
    font-size: 24px;
}

.faq-answer {
    font-style: italic;
    font-size: 18px;
}