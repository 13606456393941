.gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.gallery-container img {
    object-fit: contain !important;
    object-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 1024px) {
    .gallery-container img {
        object-fit: cover !important;
    }
}