.header-container {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #0000001a;
}

.header-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Abhaya Libre", serif;
  font-size: 32px;
  font-weight: 600;
  cursor: pointer;
}

.logged-in-user-details-container {
  display: flex;
  position: absolute;
  right: 20px;
  gap: 10px;
  align-items: center;
}

.logged-in-user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.welcome-text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #727272;
}

.logged-in-user-name {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--primary-color);
  cursor: pointer;
}

.avatar {
    width: 25px;
    height: 25px;
}

.sidebar-container {
  position: absolute;
  left: 20px;
}

.sidebar-pane {
  width: 300px;
}

.profile-menu-items {
  width: 200px;
}

.menu-quick-links-container {
  align-items: center;
  box-shadow: 0px 5px 10px 0px #0000001a;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  padding: 10px 20px;
}

.menu-quick-links-container button {
  color: #000;
  font-weight: 400;
}

@media screen and (max-width: 1024px) {

  .header-container {
    justify-content: center;
    gap: 20px;
  }

  .logged-in-user-details-container {
    display: none; /* Hide details on smaller screens */
  }

  .sidebar-pane {
    width: 90%;
  }
}