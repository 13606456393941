.react-calendar {
  border-width: thin;
  background: white;
  padding: 10px;
}

.react-datetime-picker__wrapper {
  border: 1px solid #000;
  padding: 5px 10px;
}

.react-calendar__tile--active {
  background-color: #000;
  border-radius: 5px;
}

.react-calendar__tile--now,
.react-calendar__tile--now:hover {
  background: #a47a3e !important;
  border-radius: 5px;
  color: #fff;
}

abbr {
  text-decoration: unset;
}
