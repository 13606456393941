.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  background: var(--primary-color) !important;
  border: unset !important;
  border-radius: unset !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  height: 30px !important;
}

.secondary-button {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: unset !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  height: 30px !important;
}

.button:disabled,
.secondary-button:disabled {
  opacity: 0.4 !important;
}

.event-date-time-container {
  display: flex;
  align-items: center;
}

.event-date-time-image {
  margin-top: 2px;
}

.event-date-time {
  font-size: 14px;
  font-weight: 500;
}

.event-dates {
  display: flex;
  flex-direction: column;
}
/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.checkbox-selected {
  color: #000000;
}

.checkbox-label {
  align-items: flex-start !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.h-100 {
  height: 100%;
}

.outlet-container {
  min-height: calc(100vh - 160px);
}

.modal-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-right: 10px;
}

.chatbot-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensures the chatbot is above other elements */
}
