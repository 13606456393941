.contact-tile-root {
  display: flex;
  padding: 10px;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.contact-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: calc(100% - 100px);
}

.contact-name {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.contact-description {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: #93989A;
}

.unread-count-badge {
  padding: 4px, 8px, 4px, 8px;
  border-radius: 50px;
  background-color: #a47a3e;
  color: #ffffff;
  font-weight: 500;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
