.chat-footer-container {
  height: 50px;
  border-top: 1px solid #f7f7f7;
}

.chat-footer {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.new-chat-button {
  display: flex !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  gap: 10px !important;
  color: var(--primary-color) !important;
  text-transform: capitalize !important;
  width: 100% !important;
}

.chat-container {
  height: 100%;
  overflow-y: auto;
}

.contact-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.contact-list-item {
  border-bottom: 1px solid #f7f7f7;
}

.contact-list-item:hover {
  transform: scale(1.05); /* Zoom effect on hover */
  background-color: #fafafa !important;
}

.modal-root {
  width: 60%;
  max-height: 60%;
  padding: 20px;
  overflow-y: auto;
  background: #fff;
}

.chat-header-container {
  display: flex;
}

.chat-search-container {
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
}
