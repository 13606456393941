.profile-details-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}

.profile-details-heading {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;

}

.profile-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: 10px;
}

.profile-data-label {
    font-size: 12px;
    font-weight: 400;
    color: #d2d2d2;
}

.profile-data-value {
    font-size: 16px;
    font-weight: 500;
}

.separator {
    width: 100%;
}