@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
}

:root {
  --primary-color: #000000;
}

.highlight-text {
  color: var(--primary-color);
}