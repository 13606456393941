.no-content-main-container,
.no-content-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.no-content-image-container {
  /* height: 80%; */
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* .no-content-image {
  display: block;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%, -50%);
} */

.no-content-description-container {
  height: unset;
}

.no-content-description {
  font-family: "Inter", sans-serif;
  font-size: 26px;
  color: #a47a3e;
  font-weight: 300;
}

.no-content-sub-text {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  color: #7e7e7e;
  font-family: 400;
}
