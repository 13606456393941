.message-footer-container {
  height: 100px;
  border-top: 1px solid #F7F7F7;
}

.message-input-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 5px;
  align-items: flex-start;
  padding: 10px;
}

.message-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.character-count-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.character-count {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.message-body-container {
  height: 100%;
  overflow-y: auto;
}

.message-body {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.contact-info-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.message-tile {
  display: flex;
  width: 100%;
}

.admin-message-footer {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-top: 1px solid#F7F7F7;
}

.admin-warning-text {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 500;
}

.chat-header-name {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.date-indicator {
  text-align: center;
  margin: 10px 0;
  font-size: 12px;
  color: #888;
}