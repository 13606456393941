.dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.tab-panel {
    padding: 0 !important;
}

.tabs-filters-container {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.tabs-container {
    margin-bottom: 1px;
    /* width: 100%; */
}

.tabs-container .MuiTab-root {
    font-size: 12px !important;
}

.tabs-container .Mui-selected {
    color: var(--primary-color) !important; 
    font-size: 16px !important;
}

.filters-container {
    display: flex;
    align-items: center;
    /* width: 100%; */
    justify-content: flex-end;
    gap: 20px;
    flex-wrap: wrap;
}

.select-container, .create-buttons-container, search-container {
    display: flex;
    align-items: center;
    /* width: 100%; */
}

.custom-select {
    border-radius: unset !important;
    border: 1px solid #000000 !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
    font-weight: 400 !important;

    .MuiOutlinedInput-notchedOutline {
        border: unset !important;
    }

    .MuiSelect-select {
        min-width: 100px !important;
    }
}

.create-button {
    background: #FFFFFF !important;
    border: 1px solid #000000 !important;
    border-radius: unset !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.create-button:focus {
    background: #FFFFFF !important;
}

.create-button:hover {
    color: var(--primary-color) !important;
}

.add-invite-button {
    background: var(--primary-color) !important;
    border: unset !important;
    border-radius: unset !important;
    color: #FFFFFF !important;
    text-transform: capitalize !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.add-invite-button:focus {
    background: var(--primary-color) !important;
}

.add-invite-button span {
    display: flex;
    gap: 5px;
    align-items: center;
}

.add-invite-button:disabled {
    opacity: 0.4 !important;
}

.search-text-control {
    border-radius: unset !important;
    border: 1px solid #000000 !important;
    font-size: 12px !important;
    font-weight: 400 !important;

    .MuiOutlinedInput-notchedOutline {
        border: unset !important;
    }

    .MuiInputBase-input {
        padding: 8px 10px !important;
        /* height: 30px !important;  */
    }
}