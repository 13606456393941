.verify-rsvp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  margin: auto auto;
  height: 100vh;
  gap: 10px;
}

.verify-rsvp-heading {
  font-family: "Abhaya Libre", serif;
  font-size: 72px;
  font-weight: 800;
}

.verify-rsvp-sub-heading {
  font-family: "Bona Nova", serif;
  font-size: 16px;
  font-weight: 400;
  flex-direction: column;
  display: flex;
}

.verify-rsvp-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
}

.verify-rsvp-form-control-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.verify-rsvp-form-control {
    border: 1px solid #000000 !important;
    height: 50px !important;
    width: 260px !important;
    border-radius: 50px;
}

.verify-rsvp-form-submit-icon {
    height: 50px !important;
    width: 50px !important;
}

.otp-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.otp-form-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.otp-fields-container {

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.otp-field-input {
  border: 1px solid #000000;
  padding: 5px;
  height: 40px;
  width: 40px;
  border-radius: 7px;
  text-align: center;
}

.get-otp-button:disabled {
  opacity: 0.4;
}

.rsvp-enter-details-container {
  font-size: 16px;
  font-weight: 500;
}

.consent-control {
  width: 50%;
  text-align: left;
}

.consent-control label {
  align-items: start;
  margin: 0;
}

.consent-control .MuiCheckbox-root{
  padding-top: 2px;
}

@media screen and (max-width: 1024px) {
  .verify-rsvp-container {
    justify-content: start;
    margin-top: 50px;
  }

  .verify-rsvp-heading {
    display: flex;
    flex-direction: column;
    font-size: 50px;
    line-height: 1;
  }

  .verify-rsvp-sub-heading.welcome-msg {
    font-size: 24px;
  }

  .verify-rsvp-sub-heading {
    font-size: 14px;
    text-transform: capitalize;
  }

  .consent-control {
    margin-top: 20px;
    padding: 0 10px;
    max-width: 600px;
    width: 100%;
  }

  .consent-control .MuiFormControlLabel-label {
    font-size: 14px;
  }
}